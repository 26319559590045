import "./EditTemplate.css";

import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import LoaderButton from "../components/LoaderButton";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";
import ViewTemplate from "./ViewTemplate";

export default function EditTemplate(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [connectionRequestText, setConnectionRequestText] = useState("");
  const [firstFollowUpText, setFirstFollowUpText] = useState("none");
  const [firstFollowUpDays, setFirstFollowUpDays] = useState(1);
  const [hasFirstFollowup, setHasFirstFollowup] = useState(false);
  const [secondFollowUpText, setSecondFollowUpText] = useState("none");
  const [secondFollowUpDays, setSecondFollowUpDays] = useState(2);
  const [hasSecondFollowup, setHasSecondFollowup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showView, setShowView] = useState(false);

  useEffect(() => {
    function loadTemplate() {
      return API.get("templates", `/templates/${props.templateId}`);
    }

    async function onLoad() {
      try {
        const template = await loadTemplate();
        setTitle(template.title);
        setDescription(template.description);
        setConnectionRequestText(template.connectionRequestText);
        "firstFollowUpText" in template
          ? setFirstFollowUpText(template.firstFollowUpText)
          : setFirstFollowUpText("none");
        "firstFollowUpDays" in template
          ? setFirstFollowUpDays(template.firstFollowUpDays)
          : setFirstFollowUpDays("none");
        "hasFirstFollowup" in template
          ? setHasFirstFollowup(template.hasFirstFollowup)
          : setHasFirstFollowup("none");
        "secondFollowUpText" in template
          ? setSecondFollowUpText(template.secondFollowUpText)
          : setSecondFollowUpText("none");
        "secondFollowUpDays" in template
          ? setSecondFollowUpDays(template.secondFollowUpDays)
          : setSecondFollowUpDays("none");
        "hasSecondFollowup" in template
          ? setHasSecondFollowup(template.hasSecondFollowup)
          : setHasSecondFollowup("none");
        setIsLoading(false);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.templateId]);

  function validateForm() {
    return (
      title.length > 6 &&
      description.length > 6 &&
      connectionRequestText.length > 5 &&
      firstFollowUpText.length > 3
    );
  }

  function saveTemplate(template) {
    return API.put("templates", `/templates/${props.templateId}`, {
      body: template,
    });
  }

  function handleFirstFollowup() {
    setHasFirstFollowup(!hasFirstFollowup);
  }

  function handleSecondFollowup() {
    setHasSecondFollowup(!hasSecondFollowup);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveTemplate({
        title,
        description,
        connectionRequestText,
        firstFollowUpText,
        firstFollowUpDays,
        hasFirstFollowup,
        secondFollowUpText,
        secondFollowUpDays,
        hasSecondFollowup,
      });
      setIsLoading(false);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  return (
    <div>
      {showView ? (
        <ViewTemplate templateId={props.templateId} />
      ) : (
        <div className="EditTemplate">
          <span onClick={() => setShowView(true)} className="other-btn">
            <MdArrowBack fontSize="14px" />
            Back
          </span>{" "}
          <br className="clearfix" />
          {!isLoading ? (
            <form onSubmit={handleSubmit}>
              <FormGroup controlId="title">
                <ControlLabel>Title</ControlLabel>
                <FormControl
                  value={title}
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </FormGroup>
              <FormGroup controlId="description">
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  value={description}
                  componentClass="textarea"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
              <FormGroup controlId="connectionRequest">
                <ControlLabel>Connection Request</ControlLabel>
                <FormControl
                  value={connectionRequestText}
                  componentClass="textarea"
                  onChange={(e) => setConnectionRequestText(e.target.value)}
                />
              </FormGroup>
              <FormGroup controlId="hasFirstFollowup">
                <ControlLabel>Send Follow Up?</ControlLabel>
                <input
                  checked={hasFirstFollowup}
                  onChange={handleFirstFollowup}
                  type="checkbox"
                />
              </FormGroup>
              {hasFirstFollowup ? (
                <div>
                  <FormGroup controlId="firstFollowUpDays">
                    <ControlLabel>How many days after?</ControlLabel>
                    <FormControl
                      value={firstFollowUpDays}
                      type="number"
                      onChange={(e) => setFirstFollowUpDays(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup controlId="firstFollowUpText">
                    <ControlLabel>Followup Message</ControlLabel>
                    <FormControl
                      value={firstFollowUpText}
                      componentClass="textarea"
                      onChange={(e) => setFirstFollowUpText(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup controlId="hasSecondFollowup">
                    <ControlLabel>Send Another Follow Up?</ControlLabel>
                    <input
                      checked={hasSecondFollowup}
                      onChange={handleSecondFollowup}
                      type="checkbox"
                    />
                  </FormGroup>
                </div>
              ) : null}
              {hasSecondFollowup ? (
                <div>
                  <FormGroup controlId="secondFollowUpDays">
                    <ControlLabel>How many days after?</ControlLabel>
                    <FormControl
                      value={secondFollowUpDays}
                      type="number"
                      onChange={(e) => setSecondFollowUpDays(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup controlId="secondFollowUpText">
                    <ControlLabel>Second Followup Message</ControlLabel>
                    <FormControl
                      value={secondFollowUpText}
                      componentClass="textarea"
                      onChange={(e) => setSecondFollowUpText(e.target.value)}
                    />
                  </FormGroup>
                </div>
              ) : null}

              <LoaderButton
                className="other-btn"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Save
              </LoaderButton>
            </form>
          ) : (
            <IosRefresh fontSize="60px" color="#0085ef" rotate={true} />
          )}
        </div>
      )}
    </div>
  );
}
