import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import Loading from "./Loading";
import "./EditContact.css";

export default function EditContact(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [linkedinUrl, setLinkedinUrl] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [contact, setContact] = useState("");
    const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function loadContact() {
      return API.get("contacts", `/contacts/${props.match.params.id}`);
    }

    async function onLoad() {
      try {
        const contact = await loadContact();
        setEmail(contact.email);
        setPassword(contact.password);
        setFirstName(contact.firstName);
        setLastName(contact.lastName);
        setLinkedinUrl(contact.linkedinUrl);
        setContact(contact)
        setIsLoading(false)
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function validateForm() {
    return firstName.length > 0;
  }

  function saveContact(contact) {
    return API.put("contacts", `/contacts/${props.match.params.id}`, {
      body: contact
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveContact({ email, password, firstName, lastName, linkedinUrl });
      props.history.push("/");
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="EditContact">
      <div className='form-wrapper'>
        <a href="/accounts">accounts</a> / <a href={`/accounts/${contact.accountId}`}>{contact.campaignUsername}</a> / <a href={`/campaigns/${contact.campaignId}`}>{contact.campaignSlug}</a> / <a href={`/contacts/${contact.contactId}`}>{contact.linkedinUsername}</a> / edit
        {
            !isLoading ? (
                <form onSubmit={handleSubmit}>
                <p>edit</p>
                <FormGroup controlId="email">
                    <ControlLabel>Email</ControlLabel>
                    <FormControl
                        value={email}
                        type='text'
                        onChange={e => setEmail(e.target.value)}
                    />
                </FormGroup>
              <LoaderButton
                block
                type="submit"
                bsSize="large"
                bsStyle="primary"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Save
              </LoaderButton>
            </form>
            ) : <Loading />
        }
      </div>
    </div>
  );
}