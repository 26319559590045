import "./ViewTemplate.css";

import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import EditTemplate from "./EditTemplate";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import MdCreate from "react-ionicons/lib/MdCreate";

export default function ViewTemplate(props) {
  const [template, setTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    function loadTemplate() {
      return API.get("templates", `/templates/${props.templateId}`);
    }

    async function onLoad() {
      try {
        const template = await loadTemplate();

        setTemplate(template);
        setIsLoading(true);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.templateId]);

  return (
    <div className="ViewTemplate text-left">
      {isLoading ? (
        showEdit ? (
          <EditTemplate
            setScreen={props.setScreen}
            templateId={props.templateId}
            props={props}
          />
        ) : (
          <Row>
            <Col sm={12}>
              <div>
                <span onClick={() => setShowEdit(true)} className="other-btn">
                  <MdCreate fontSize="14px" />
                  Edit
                </span>
                <br />
                <br />
                <p>
                  <b>{template.title} </b>
                </p>
                <p>{template.description}</p>
              </div>
              <hr />
              <br />
              <p>
                <b>Connection Request</b>
              </p>
              <p>{template.connectionRequestText}</p>
              {template.hasFirstFollowup ? (
                <p>
                  <br />
                  <small className="blue">
                    wait {template.firstFollowUpDays} days
                  </small>
                  <br />
                  <br />
                </p>
              ) : null}
              {template.hasFirstFollowup ? (
                <div>
                  <p>
                    <b>Followup</b>
                  </p>
                  <p>{template.firstFollowUpText}</p>
                </div>
              ) : null}
              {template.hasSecondFollowup ? (
                <p>
                  <br />
                  <small className="blue">
                    wait {template.secondFollowUpDays} days
                  </small>
                  <br />
                  <br />
                </p>
              ) : null}
              {template.hasSecondFollowup ? (
                <div>
                  <p>
                    <b>Second Followup</b>
                  </p>
                  <p>{template.secondFollowUpText}</p>
                </div>
              ) : null}
            </Col>
          </Row>
        )
      ) : (
        <IosRefresh fontSize="60px" color="#0176ff" rotate={true} />
      )}
    </div>
  );
}
