import "./Loading.css";

import IosRefresh from "react-ionicons/lib/IosRefresh";
import React from "react";

export default function Loading() {
  return (
    <div className="text-center">
      <br />
      <br />
      <br />
      <br />
      <IosRefresh fontSize="60px" color="#0176ff" rotate={true} />
    </div>
  );
}
