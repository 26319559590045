import { Col, Modal, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import Loading from "./Loading";
import Switch from "react-switch";
import moment from "moment";

export default function GodView(props) {
  const [campaigns, setCampaign] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pausedContacts, setPausedContacts] = useState(0);
  const [pausedRequests, setPausedRequests] = useState(0);
  const [pausedConnections, setPausedConnections] = useState(0);
  const [pausedConvos, setPausedConvos] = useState(0);
  const [activeContacts, setActiveContacts] = useState(0);
  const [activeRequests, setActiveRequests] = useState(0);
  const [activeConnections, setActiveConnections] = useState(0);
  const [activeConvos, setActiveConvos] = useState(0);
  const [viewCampaignModal, setViewCampaignModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [loadingConvos, setLoadingConvos] = useState(false);

  useEffect(() => {
    function loadCampaigns() {
      return API.get("campaigns", `/godview`);
    }

    async function onLoad() {
      try {
        const campaigns = await loadCampaigns();

        setCampaign(campaigns);

        setPausedContacts(
          campaigns
            .filter((campaign) => campaign.paused === true)
            .reduce(function (a, b) {
              return a + b["contacts"];
            }, 0)
        );
        setPausedRequests(
          campaigns
            .filter((campaign) => campaign.paused === true)
            .reduce(function (a, b) {
              return a + b["requests"];
            }, 0)
        );
        setPausedConnections(
          campaigns
            .filter((campaign) => campaign.paused === true)
            .reduce(function (a, b) {
              return a + b["connections"];
            }, 0)
        );
        setPausedConvos(
          campaigns
            .filter((campaign) => campaign.paused === true)
            .reduce(function (a, b) {
              return a + b["conversations"];
            }, 0)
        );
        setActiveContacts(
          campaigns
            .filter((campaign) => campaign.paused !== true)
            .reduce(function (a, b) {
              return a + b["contacts"];
            }, 0)
        );
        setActiveRequests(
          campaigns
            .filter((campaign) => campaign.paused !== true)
            .reduce(function (a, b) {
              return a + b["requests"];
            }, 0)
        );
        setActiveConnections(
          campaigns
            .filter((campaign) => campaign.paused !== true)
            .reduce(function (a, b) {
              return a + b["connections"];
            }, 0)
        );
        setActiveConvos(
          campaigns
            .filter((campaign) => campaign.paused !== true)
            .reduce(function (a, b) {
              return a + b["conversations"];
            }, 0)
        );
        setIsLoading(true);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function saveCampaign(campaign) {
    return API.put("campaigns", `/campaigns/${campaign.campaignId}`, {
      body: campaign,
    });
  }

  async function showViewCampaignModal(campaignId) {
    setViewCampaignModal(true);
    setLoadingConvos(true);
    const contacts = await loadContacts(campaignId);
    setContacts(contacts);
    setLoadingConvos(false);
  }

  function loadContacts(campaignId) {
    return API.get("contacts", `/campaigns/${campaignId}/contacts`);
  }

  function closeModal() {
    setViewCampaignModal(false);
    setContacts([]);
  }

  async function handleActivateCampaign(campaign) {
    const updatedCampaign = {
      title: campaign.title,
      dailyLimit: campaign.dailyLimit,
      paused: !campaign.paused,
      slug: campaign.slug,
      accountId: campaign.accountId,
      campaignId: campaign.campaignId,
      username: campaign.username,
      userId: campaign.userId,
      createdAt: campaign.createdAt,
      conversations: campaign.conversations,
      requests: campaign.requests,
      connections: campaign.connections,
      contacts: campaign.contacts,
      description: campaign.description,
    };

    try {
      await saveCampaign(updatedCampaign);
    } catch (e) {
      alert(e);
    }
  }

  return (
    <div className="GodView container">
      {isLoading ? (
        <div>
          <h3>Active</h3>
          <Row>
            <Col sm={3} xs={12}>
              <h4>{activeContacts}</h4>
              <p>Contacts Uploaded</p>
            </Col>
            <Col sm={3} xs={12}>
              <h4>
                {activeRequests}{" "}
                {activeContacts > 0 ? (
                  <small>
                    {((activeRequests / activeContacts) * 100).toFixed(0)}%
                  </small>
                ) : null}{" "}
              </h4>
              <p>Requests Sent</p>
            </Col>
            <Col sm={3} xs={12}>
              <h4>
                {activeConnections}{" "}
                {activeContacts > 0 ? (
                  <small>
                    {((activeConnections / activeContacts) * 100).toFixed(0)}%
                  </small>
                ) : null}{" "}
              </h4>
              <p>New Connections</p>
            </Col>
            <Col sm={3} xs={12}>
              <h4>
                {activeConvos}{" "}
                {activeContacts > 0 ? (
                  <small>
                    {((activeConvos / activeContacts) * 100).toFixed(0)}%
                  </small>
                ) : null}{" "}
              </h4>
              <p>Conversations</p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Campaign</th>
                    <th>Account</th>
                    <th>Sending Limit</th>
                    <th>Contacts</th>
                    <th>Requests</th>
                    <th>Connections</th>
                    <th>Replies</th>
                    <th>Positives</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {campaigns
                    .sort((a, b) => a.conversations - b.conversations)
                    .filter((campaign) => campaign.paused !== true)
                    .reverse()
                    .map((campaign, i) => (
                      <tr>
                        <td
                          onClick={() =>
                            showViewCampaignModal(campaign.campaignId)
                          }
                        >
                          {campaign.title}
                        </td>
                        <td>{campaign.username}</td>
                        <td>{campaign.dailyLimit}</td>
                        <td>{campaign.contacts}</td>
                        <td>{campaign.requests}</td>
                        <td>{campaign.connections}</td>
                        <td>{campaign.conversations}</td>
                        <td>{campaign.positives}</td>
                        <td>
                          <Switch
                            height={20}
                            width={35}
                            onChange={() => handleActivateCampaign(campaign)}
                            onColor={"#0085EF"}
                            checked={!campaign.paused}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <h3>Paused</h3>
          <Row>
            <Col sm={3} xs={12}>
              <h4>{pausedContacts}</h4>
              <p>Contacts Uploaded</p>
            </Col>
            <Col sm={3} xs={12}>
              <h4>
                {pausedRequests}{" "}
                {pausedContacts > 0 ? (
                  <small>
                    {((pausedRequests / pausedContacts) * 100).toFixed(0)}%
                  </small>
                ) : null}{" "}
              </h4>
              <p>Requests Sent</p>
            </Col>
            <Col sm={3} xs={12}>
              <h4>
                {pausedConnections}{" "}
                {pausedContacts > 0 ? (
                  <small>
                    {((pausedConnections / pausedContacts) * 100).toFixed(0)}%
                  </small>
                ) : null}{" "}
              </h4>
              <p>New Connections</p>
            </Col>
            <Col sm={3} xs={12}>
              <h4>
                {pausedConvos}{" "}
                {pausedContacts > 0 ? (
                  <small>
                    {((pausedConvos / pausedContacts) * 100).toFixed(0)}%
                  </small>
                ) : null}{" "}
              </h4>
              <p>Conversations</p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Campaign</th>
                    <th>Template</th>
                    <th>Account</th>
                    <th>Sending Limit</th>
                    <th>Contacts</th>
                    <th>Requests</th>
                    <th>Connections</th>
                    <th>Replies</th>
                    <th>Positives</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {campaigns
                    .sort((a, b) => a.conversations - b.conversations)
                    .filter((campaign) => campaign.paused === true)
                    .reverse()
                    .map((campaign, i) => (
                      <tr>
                        <td
                          onClick={() =>
                            showViewCampaignModal(campaign.campaignId)
                          }
                        >
                          {campaign.title}
                        </td>
                        <td>{campaign.templateId}</td>
                        <td>{campaign.username}</td>
                        <td>{campaign.dailyLimit}</td>
                        <td>{campaign.contacts}</td>
                        <td>{campaign.requests}</td>
                        <td>{campaign.connections}</td>
                        <td>{campaign.conversations}</td>
                        <td>{campaign.positives}</td>
                        <td>
                          <Switch
                            height={20}
                            width={35}
                            onChange={() => handleActivateCampaign(campaign)}
                            onColor={"#0085EF"}
                            checked={!campaign.paused}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Modal show={viewCampaignModal} dialogClassName="modal-90w">
            <Modal.Body>
              <div className="modal-card text-center">
                <Row>
                  <Col xs={12}>
                    <button onClick={() => closeModal()} className="other-btn">
                      close
                    </button>
                  </Col>
                </Row>
                <br />
                {contacts.filter(
                  (contact) =>
                    contact.hasReplied === true &&
                    "conversationMessages" in contact &&
                    contact.conversationMessages.length > 0
                ).length > 0 ? (
                  <div className="all-convos">
                    {loadingConvos ? (
                      <Loading />
                    ) : (
                      contacts
                        .filter(
                          (contact) =>
                            contact.hasReplied === true &&
                            "conversationMessages" in contact &&
                            contact.conversationMessages.length > 0
                        )
                        .map((contact) => (
                          <div className="text-left">
                            <br />
                            <h5>
                              {" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={contact.linkedinUrl}
                              >
                                <img
                                  height="15px"
                                  alt="linekdin logo"
                                  style={{
                                    position: "relative",
                                    bottom: "1px",
                                  }}
                                  src="https://s3.amazonaws.com/adsgen/linkedin.svg"
                                />
                              </a>{" "}
                              {contact.firstName} {contact.lastName}
                            </h5>
                            <br />
                            {contact.conversationMessages.map((msg, i) => (
                              <div key={i}>
                                <p>
                                  <b>
                                    {msg.user.firstName}{" "}
                                    {msg.user.lastName !== "none"
                                      ? msg.user.lastName
                                      : null}{" "}
                                  </b>
                                  <small>
                                    {moment(msg.createdAt).format(
                                      "MMM Do, h:mm:ss a"
                                    )}
                                  </small>
                                </p>
                                <p>{msg.content}</p>
                                <br />
                              </div>
                            ))}
                            <hr />
                          </div>
                        ))
                    )}
                  </div>
                ) : (
                  <div>No conversations yet</div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
