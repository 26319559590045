import "./Pricing.css";

import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

export default class Pricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }
  render() {
    return (
      <div className="Pricing container">
        <Row>
          <Col sm={10} smOffset={1}>
            <h1 className="text-center">Simple Pricing for Everyone</h1>
            <h3 className="text-center">
              Your first campaign is free (no credit card required) and you can
              cancel anytime.
            </h3>
            <br />
            <br />
            <br />
          </Col>
          <Col sm={4}>
            <div class="pricing text-center">
              <img
                height="150"
                alt="Gordon Leslie"
                src="https://conversify-marketing.s3.amazonaws.com/Individual.png"
              />
              <br />
              <h3 className="blue">Individual</h3>
              <h1 className="blue">$99</h1>
              <div className="line"></div>
              <p>1 rep</p>
              <br />
              <p>Unlimited prospects</p>
              <br />
              <p>A/B Testing</p>
              <br />
              <p>Automated Outreach</p>
              <br />
              <p>Automated Followups</p>
              <br />
              <p>Chat Support</p>
              <br />
              <br />
              <a className="btn-primary" href="/r/pricing">
                Get Started
              </a>
              <br />
              <br />
            </div>
          </Col>
          <Col sm={4}>
            <div class="pricing text-center popular">
              <img
                height="150"
                alt="Adam Scanlon"
                src="https://conversify-marketing.s3.amazonaws.com/Startup.png"
              />
              <br />
              <h3>Startups</h3>
              <h1>$499</h1>
              <div className="line darkBorder"></div>
              <p>Unlimited reps</p>
              <br />
              <p>Unlimited prospects</p>
              <br />
              <p>A/B Testing</p>
              <br />
              <p>Automated Outreach</p>
              <br />
              <p>Automated Followups</p>
              <br />
              <p>Chat Support</p>
              <br />
              <br />
              <a className="btn-primary dark" href="/r/pricing">
                Get Started
              </a>
              <br />
              <br />
            </div>
          </Col>
          <Col sm={4}>
            <div class="pricing text-center">
              <img
                height="150"
                alt="David Hurley"
                src="https://conversify-marketing.s3.amazonaws.com/Fully_Managed.png"
              />
              <br />
              <h3 className="blue">Fully Managed</h3>
              <h1 className="blue">$1999</h1>
              <div className="line"></div>
              <p>Unlimited reps</p>
              <br />
              <p>Unlimited prospects</p>
              <br />
              <p>A/B Testing</p>
              <br />
              <p>Automated Outreach</p>
              <br />
              <p>Automated Followups</p>
              <br />
              <p>Conversation Hacker</p>
              <br />
              <br />
              <a className="btn-primary" href="/r/pricing">
                Get Started
              </a>
              <br />
              <br />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
