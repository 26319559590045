import "./About.css";

import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

export default class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }
  render() {
    return (
      <div className="About container">
        <Row>
          <Col className="text-center" sm={10} smOffset={1}>
            <h1>
              We help great companies build and optimize conversation funnels on
              LinkedIn
            </h1>
          </Col>
          <Col sm={6} smOffset={3}>
            <h3>
              It's no secret that LinkedIn is a great platform for growing your
              network and starting conversations that benefit your business.
              That doesn't mean it's easy.
            </h3>
            <h3>
              We've seen how other companies approach this problem and much of
              it amounts to spam. You've probably seen it and frankly, it's
              starting to ruin the network. We're here to change that.
            </h3>
            <h3>
              We're proud of our platform but it will only get you so far.
              Automation and AI won't create the perfect conversation funnel on
              it's own. We want to provide that and teach you how to do the rest
              properly.
            </h3>
            <br />
            <br />
            <br />
          </Col>
          <Col sm={12}>
            <h3 className="text-center">Team</h3>
            <br />
          </Col>
          <Col sm={4}>
            <div class="team-card text-center">
              <img
                alt="Gordon Leslie"
                src="https://conversify-marketing.s3.amazonaws.com/gordon.jpeg"
              />
              <h5>Gordon Leslie</h5>
              <p>Co-Founder & CEO</p>
              <p>
                After a successful career in technology sales he started a
                growth agency that helps companies build and scale marketing
                campaigns while managing $10M+ in annual ad spend.
              </p>
            </div>
          </Col>
          <Col sm={4}>
            <div class="team-card text-center">
              <img
                alt="Adam Scanlon"
                src="https://conversify-marketing.s3.amazonaws.com/adam.jpeg"
              />
              <h5>Adam Scanlon</h5>
              <p>Co-Founder & COO</p>
              <p>
                After a successful career as an affiliate marketer he started a
                growth agency that manages $10M+ in annual ad spend. Conversion
                rate optimization is his obsession.
              </p>
            </div>
          </Col>
          <Col sm={4}>
            <div class="team-card text-center">
              <img
                height="200"
                alt="David Hurley"
                src="https://sales-captain-marketing.s3.amazonaws.com/david-small.jpg"
              />
              <h5>David Hurley</h5>
              <p>Co-Founder & CPO</p>
              <p>
                Started his career in tech sales before becoming a software
                developer and after transitioned to marketing. He works at the
                intersection of marketing, tech, and product.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
