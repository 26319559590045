import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import Loading from "./Loading";

export default function ViewTemplateItem(props) {
  const [template, setTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function loadTemplate(templateId) {
      return API.get("templates", `/templates/${templateId}`);
    }

    async function onLoad() {
      try {
        const template = await loadTemplate(props.templateId);

        setTemplate(template);
        setIsLoading(false);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.templateId]);

  return (
    <div className="ViewTemplateItem text-left">
      {!isLoading ? (
        <Row>
          <Col sm={12}>
            <div className="text-left">
              <p>
                <b>{template.title} </b>
              </p>
              <p>{template.description}</p>
            </div>
            <hr />
            <p className="text-left">
              <b>Connection Request</b>
            </p>
            <p>{template.connectionRequestText}</p>
            {template.hasFirstFollowup ? (
              <p>
                <br />
                <small className="blue">
                  wait {template.firstFollowUpDays} days
                </small>
                <br />
                <br />
              </p>
            ) : null}
            {template.hasFirstFollowup ? (
              <div>
                <p>
                  <b>Followup</b>
                </p>
                <p>{template.firstFollowUpText}</p>
              </div>
            ) : null}
            {template.hasSecondFollowup ? (
              <p>
                <br />
                <small className="blue">
                  wait {template.secondFollowUpDays} days
                </small>
                <br />
                <br />
              </p>
            ) : null}
            {template.hasSecondFollowup ? (
              <div>
                <p>
                  <b>Second Followup</b>
                </p>
                <p>{template.secondFollowUpText}</p>
              </div>
            ) : null}
          </Col>
        </Row>
      ) : (
        <Loading />
      )}
    </div>
  );
}
