import "./Conversations.css";
import "react-dropdown/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";

import { Col, ListGroupItem, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import Dropdown from "react-dropdown";
import Loading from "./Loading";
import _ from "lodash";
import moment from "moment";

export default function Contacts(props) {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentContact, setCurrentContact] = useState({});
  const [campaigns, setCampaigns] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState(null);

  useEffect(() => {
    async function onLoad() {
      try {
        let contacts = await loadContacts();
        contacts = contacts.filter(
          (contact) =>
            contact.hasReplied === true &&
            contact.conversationMessages.length > 0
        );
        const groupByCampaignSlug = _.groupBy(contacts, "campaignSlug");
        const campaignSlugs = Object.keys(groupByCampaignSlug);
        const campaigns = [];
        for (let slugIndex in campaignSlugs) {
          campaigns.push({
            label: campaignSlugs[slugIndex],
            value: campaignSlugs[slugIndex],
          });
        }
        setCampaigns(campaigns);
        setSelectedCampaign(campaigns[0]);
        setContacts(contacts);
        setSelectedContacts(
          contacts.filter(
            (contact) =>
              contact.hasReplied === true &&
              contact.campaignSlug === campaigns[0].value
          )
        );
        setCurrentContact(contacts[0]);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated]);

  function loadContacts() {
    return API.get("contacts", "/conversifyconversations");
  }

  function updateCampaign(campaign) {
    setSelectedCampaign(campaign);
    setSelectedContacts(
      contacts.filter((contact) => contact.campaignSlug === campaign.value)
    );
    setCurrentContact(
      contacts.filter((contact) => contact.campaignSlug === campaign.value)[0]
    );
  }

  return (
    <div className="Conversations container">
      {isLoading ? (
        <Loading />
      ) : contacts.length === 0 ? (
        <div>
          <Row>
            <Col sm={4}>
              <div className="convos-container text-center">
                <div className="overlay">
                  <img
                    alt="conversations here"
                    src="https://conversify-marketing.s3.amazonaws.com/followup.svg"
                  />
                </div>
                <br />
                <h4>Leads will appear here</h4>
              </div>
            </Col>
            <Col sm={4}>
              <div className="convos-container text-center">
                <div className="overlay">
                  <img
                    alt="conversations here"
                    src="https://conversify-marketing.s3.amazonaws.com/chat.svg"
                  />
                </div>
                <br />
                <h4>Conversation will appear here</h4>
              </div>
            </Col>
            <Col sm={4}>
              <div className="convos-container text-center">
                <div className="overlay">
                  <img
                    alt="conversations here"
                    src="https://conversify-marketing.s3.amazonaws.com/profile.png"
                  />
                </div>
                <br />
                <h4>Lead intelligence will appear here</h4>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <Row>
            <Col sm={3}>
              <Dropdown
                value={selectedCampaign}
                options={campaigns}
                onChange={updateCampaign}
                placeholder="Select a campaign"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <div className="convos-container text-center">
                {selectedContacts.map((contact, i) => (
                  <ListGroupItem
                    key={i}
                    onClick={() => setCurrentContact(contact)}
                    className={
                      currentContact.fullName === contact.fullName
                        ? "active"
                        : null
                    }
                  >
                    <h4>
                      {contact.fullName}{" "}
                      <span className="marker pull-right">
                        {contact.conversationMessages.length}
                      </span>
                    </h4>
                  </ListGroupItem>
                ))}
              </div>
            </Col>
            <Col sm={6}>
              <div className="convos-container text-left">
                <br />
                {currentContact.conversationMessages.map((msg, i) => (
                  <li key={i}>
                    <p>
                      <b>
                        {msg.user.firstName} {msg.user.lastName}
                      </b>{" "}
                      <small className="blue">
                        sent {moment(msg.createdAt).format("MMM Do, h:mm:ss a")}
                      </small>
                    </p>
                    <p>{msg.content}</p>
                  </li>
                ))}
              </div>
            </Col>
            <Col sm={3}>
              <div className="convos-container text-center">
                <br />
                <br />
                <h5>{currentContact.fullName}</h5>
                <p>{currentContact.linkedinHeadline}</p>
                <p className="text-center">
                  <a
                    alt="link to linkedin profile"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={currentContact.linkedinUrl}
                  >
                    View Profile
                  </a>
                </p>
                <p className="text-center">
                  <a
                    alt="link to linkedin profile"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={currentContact.conversationUrl}
                  >
                    View Conversation
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
