import "./NewAccount.css";

import { API, Auth } from "aws-amplify";
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";

import IosRefresh from "react-ionicons/lib/IosRefresh";
import MdArrowForward from "react-ionicons/lib/MdArrowForward";
import ReactPixel from "react-facebook-pixel";
import Settings from "./Settings";

export default function NewAccount(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [showUpgrade, setShowUpgrade] = useState(false);

  function validateForm() {
    return password.length >= 6;
  }

  useEffect(() => {
    function loadProfile(userId) {
      return API.get("profiles", `/myprofile/${userId}`);
    }

    function loadAccounts() {
      return API.post("accounts", "/adminaccounts", {
        body: {
          userId: "us-east-1:dd6cf721-4bde-44f6-90ce-0d3da321fd66",
        },
      });
    }

    async function onLoad() {
      try {
        const user = await Auth.currentUserInfo();
        const accounts = await loadAccounts();
        setAccounts(accounts);
        try {
          const profile = await loadProfile(user["id"]);
          setProfile(profile);
          setIsPageLoading(true);
        } catch (e) {
          console.log(e);
          props.props.handleLogout();
          console.log("cant load profile");
        }
      } catch (e) {
        console.log(e);
        console.log("cant find user or accounts");
      }
    }

    onLoad();
  }, [props.props]);

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const account = await createAccount({
        email,
        password,
        firstName: "none",
        linkedinUrl: "none",
        lastName: "none",
        profileId: profile.profileId,
      });
      ReactPixel.track("CompleteRegistration");
      if (window.location.pathname === "/accounts/new") {
        props.props.history.push(`/verify/${account.accountId}`);
      } else {
        props.history.push(`/verify/${account.accountId}`);
      }
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function createAccount(account) {
    return API.post("accounts", "/accounts", {
      body: account,
    });
  }

  return (
    <div className="NewAccount">
      {!isPageLoading ? (
        <IosRefresh fontSize="60px" color="#0176ff" rotate={true} />
      ) : accounts.length > 0 && profile.paid === false ? (
        <Row>
          {showUpgrade ? (
            <Settings changeScreen={props.changeScreen} />
          ) : (
              <Col sm={12}>
                <p className="text-left">
                  <b>Upgrade</b>
                </p>
                <br />
                <p className="text-left">
                  You'll have to upgrade if you want to connect another account.
              </p>
                <br />
                <p>
                  <span
                    className="other-btn pull-left"
                    onClick={() => setShowUpgrade(true)}
                  >
                    Continue
                  <MdArrowForward fontSize="16px" />
                  </span>
                </p>
              </Col>
            )}
        </Row>
      ) : profile.accountCount === profile.accountsPurchased ? (
        <Row>
          {showUpgrade ? (
            <Settings changeScreen={props.changeScreen} />
          ) : (
              <Col sm={12}>
                <p className="text-left">
                  <b>
                    {" "}
                  You've paid for {profile.accountsPurchased}{" "}
                    {profile.accountsPurchased === 1 ? "account" : "accounts"}
                  </b>
                </p>
                <br />
                <p className="text-left">
                  You'll have to upgrade if you want to connect another account.
              </p>
                <br />
                <p>
                  <span
                    className="other-btn pull-left"
                    onClick={() => setShowUpgrade(true)}
                  >
                    Continue
                  <MdArrowForward fontSize="16px" />
                  </span>
                </p>
              </Col>
            )}
        </Row>
      ) : (
              <Row>
                <Col sm={12}>
                  <form onSubmit={handleSubmit}>
                    <p className="text-left">
                      <img
                        alt="linkedin logo"
                        height="35"
                        src="https://conversify-marketing.s3.amazonaws.com/linkedin.svg"
                      />
                    </p>
                    <br />
                    <p className="text-left">
                      <b>Connect a LinkedIn account you'd like to manage</b>
                    </p>
                    <FormGroup controlId="email">
                      <ControlLabel>Email</ControlLabel>
                      <FormControl
                        value={email}
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup controlId="password">
                      <ControlLabel>Password</ControlLabel>
                      <FormControl
                        value={password}
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormGroup>
                    <button
                      className="other-btn pull-right"
                      type="submit"
                      isLoading={isLoading}
                      disabled={!validateForm()}
                    >
                      Connect Account
                <MdArrowForward fontSize="16px" />
                    </button>
                  </form>
                </Col>
              </Row>
            )}
    </div>
  );
}
