import "./ViewContact.css";

import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import Loading from "./Loading";
import moment from "moment";

export default function ViewContact(props) {
  const [contact, setContact] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function loadContact() {
      return API.get("contacts", `/contacts/${props.match.params.id}`);
    }

    async function onLoad() {
      try {
        const contact = await loadContact();
        setContact(contact);
        setIsLoading(true);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  return (
    <div className="ViewContact container">
      {isLoading ? (
        <div>
          <a href={`/campaigns/${contact.campaignId}`}>back</a>
          <h1>
            {contact.linkedinUsername}{" "}
            <a
              alt="link to linkedin profile"
              target="_blank"
              rel="noopener noreferrer"
              href={contact.linkedinUrl}
            >
              <img
                alt="linkedin logo"
                height="35"
                src="https://conversify-marketing.s3.amazonaws.com/linkedin.svg"
              />
            </a>{" "}
          </h1>
          {contact.conversationMessages ? (
            <ul>
              {contact.conversationMessages.map((msg) => (
                <li>
                  <p>
                    <b>
                      {msg.user.firstName} {msg.user.lastName}
                    </b>{" "}
                    <small className="blue">
                      {moment(msg.createdAt).format("MMM Do, h:mm:ss a")}
                    </small>
                  </p>
                  <p>{msg.content}</p>
                </li>
              ))}
            </ul>
          ) : (
            <ul>
              <li>
                <p>
                  <b>Connection Request</b>
                </p>
                <p>{contact.connectionRequestText}</p>
              </li>
              {contact.hasFirstFollowup ? (
                <li>
                  <p className="highlight">
                    wait {contact.firstFollowUpDays} days
                  </p>
                </li>
              ) : null}
              {contact.hasFirstFollowup ? (
                <li>
                  <p>
                    <b>Followup</b>
                  </p>
                  <p>{contact.firstFollowUpText}</p>
                </li>
              ) : null}
              {contact.hasSecondFollowup ? (
                <li>
                  <p className="highlight">
                    wait {contact.secondFollowUpDays} days
                  </p>
                </li>
              ) : null}
              {contact.hasSecondFollowup ? (
                <li>
                  <p>
                    <b>Second Followup</b>
                  </p>
                  <p>{contact.secondFollowUpText}</p>
                </li>
              ) : null}
            </ul>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
