import "./ViewAccount.css";

import { API, Auth } from "aws-amplify";
import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import Accounts from "./Accounts";
import Loading from "./Loading";

export default function ViewAccount(props) {
  const [account, setAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function loadAccounts() {
      return API.post("accounts", "/adminaccounts", {
        body: {
          userId: "us-east-1:dd6cf721-4bde-44f6-90ce-0d3da321fd66",
        },
      });
    }

    async function onLoad() {
      try {
        const user = await Auth.currentUserInfo();
        const accounts = await loadAccounts();
        const account = accounts.filter(
          (acc) => acc.email === user.attributes.email
        );
        setAccount(account);
        setIsLoading(true);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  return (
    <div className="ViewAccount">
      {isLoading ? (
        account.firstName === "none" && account.lastName === "none" ? (
          <div>
            <br />
            <br />
            <div className="form-wrapper">
              <Row>
                <Col className="text-center">
                  <p style={{ opacity: 1, textAlign: "center" }}>
                    <b>
                      {" "}
                      <span role="img" aria-label="sad">
                        😞
                      </span>{" "}
                      Hmm ...{" "}
                    </b>
                  </p>

                  <p>
                    We had an issue verifying your login information for the
                    LinkedIn account with the email {account.email}.
                  </p>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <div className="s">
                    <a
                      style={{
                        margin: "auto",
                      }}
                      className="btn-secondary"
                      href={`/verify/${props.match.params.id}`}
                    >
                      Try again
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
            <div>
              <Row>
                <Col sm={12} xs={12}>
                  <Accounts accountId={props.match.params.id} props={props} />
                </Col>
              </Row>
            </div>
          )
      ) : (
          <Loading />
        )}
    </div>
  );
}
