import "./App.css";

import { Link, withRouter } from "react-router-dom";
import { Nav, NavItem, Navbar } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";

function App(props) {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [navOn, setNavOn] = useState(false);

  const [adminLogo, setAdminLogo] = useState(null);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      const user = await Auth.currentUserInfo();
      console.log(user);
      if (user) userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }
    setAdminLogo(
      "https://conversify-marketing.s3.amazonaws.com/conversify.png"
    );
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    props.history.push("/login");
  }

  return (
    !isAuthenticating && (
      <div className="App">
        {isAuthenticated ? null : (
          <Navbar
            style={{
              background:
                window.location.pathname === "/" && !navOn
                  ? "transparent"
                  : "white",
            }}
            collapseOnSelect
          >
            <Navbar.Header>
              <Navbar.Brand>
                <Link to="/">
                  {window.location.pathname === "/" &&
                  !isAuthenticated &&
                  !navOn ? (
                    <img height="25" alt="conversify logo" src={adminLogo} />
                  ) : (
                    <img height="25" alt="conversify logo" src={adminLogo} />
                  )}
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle
                onClick={() => (navOn ? setNavOn(false) : setNavOn(true))}
              />
            </Navbar.Header>
            <Navbar.Collapse>
              {isAuthenticated ? null : (
                <Nav pullRight>
                  <LinkContainer to="/login">
                    <NavItem
                      className={
                        window.location.pathname === "/" && !navOn
                          ? "white"
                          : null
                      }
                    >
                      Log In
                    </NavItem>
                  </LinkContainer>
                  <LinkContainer to="/r/nav">
                    <NavItem
                      className={
                        window.location.pathname === "/" && !navOn
                          ? "white"
                          : null
                      }
                    >
                      Sign Up
                    </NavItem>
                  </LinkContainer>
                </Nav>
              )}
            </Navbar.Collapse>
          </Navbar>
        )}

        <Routes
          appProps={{
            isAuthenticated,
            userHasAuthenticated,
            handleLogout,
            adminLogo,
          }}
        />
      </div>
    )
  );
}

export default withRouter(App);
