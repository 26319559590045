import "./Loading.css";

import { Col, Row } from "react-bootstrap";

import React from "react";

export default function ContactsLoader() {
  return (
    <div className="container text-center">
      <Row>
        <Col sm={6} smOffset={3}>
          <h1>Uploading Contacts ...</h1>
          <p>Don't close or refresh this window. It can take 1-3 minutes.</p>
          <br />
          <img
            style={{ width: "100%" }}
            alt="loading-gif"
            src="https://media.giphy.com/media/aCFFoeahQVAeQ/source.gif"
          />
        </Col>
      </Row>
    </div>
  );
}
