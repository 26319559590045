import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import "./Terms.css";

export default class Thanks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }
  render() {
    return (
      <div className="Thanks container">
        <Row>
          <Col sm={6} smOffset={3} className='text-center'>
            <br />
            <h1>Thanks!</h1>
            <br/>
            <p>We are really excited you decided to be a customer. All customers have access to our Slack community. This gives you a chance to connect with other growth marketers and sales leaders at the top of their game.</p>
            <br/><br/>
            <p><a className='btn-primary' alt='join slack' target='_blank' rel="noopener noreferrer" href="https://meetings.hubspot.com/david1033">Request Invite</a></p>
          </Col>
        </Row>
      </div>
    );
  }
}
