import "./Home.css";

import React from "react";

export default function Home(props) {
  return (
    <div className="Home">
      {props.isAuthenticated
        ? props.history.push("/dashboard")
        : props.history.push("/r/start")}
    </div>
  );
}
